import React, { useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBInputGroup,
    MDBCheckbox,
    MDBIcon
  }
  from 'mdb-react-ui-kit';
import axios from 'axios'

import {
  faCheck,
  faTimes,
  faInfoCircle,
  faRefresh,
  faUpload 
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import 'bootstrap/dist/css/bootstrap.min.css';
//import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faSignInAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

const ApplicationForm = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP;
    let [handleSubmitBtn, setHandleSubmitBtn] = useState(false)
const countries = [
    "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina",
    "Armenia", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados",
    "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina", "Botswana",
    "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada",
    "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo, Democratic Republic of the",
    "Congo, Republic of the", "Costa Rica", "Côte d'Ivoire", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark",
    "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea",
    "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji", "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany",
    "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana", "Haiti", "Honduras", "Hungary",
    "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan",
    "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos",
    "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar",
    "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania", "Mauritius", "Mexico",
    "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia",
    "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway",
    "Oman", "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland",
    "Portugal", "Qatar", "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines",
    "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone",
    "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa", "South Sudan", "Spain", "Sri Lanka",
    "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga",
    "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine", "United Arab Emirates",
    "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
    "Yemen", "Zambia", "Zimbabwe"
  ];

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    country: '',
    gender: '',
    email: '',
    phone: '',
    category: '',
   // photo: null,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  /*const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      setFormData((prevState) => ({ ...prevState, photo: file }));
    } else {
      alert('Please upload a valid image file (jpg or png).');
    }
  };*/

  const handleReset = () => {
    setFormData({
      firstName: '',
      lastName: '',
      country: '',
      gender: '',
      email: '',
      phone: '',
      category: ''
    });
    setIsSubmitted(false);
  };

  const isFormValid = () => {
    return Object.values(formData).every((value) => value !== '' && value !== null);
  };

  const handleSubmit = () => {
    setHandleSubmitBtn(true);
    setIsSubmitted(false);
    const { firstName, lastName, country, gender, category, email, phone } = formData;
  
    const doc = new jsPDF();
    const img = new Image();
    img.src = require('./images/summit.jpg'); // Ensure this path is correct
  
    img.onload = () => {
      // Set background image
      doc.addImage(img, 'JPEG', 0, 0, 210, 297); // Assuming A4 size template
  
      // Set the font family, size, and color to match the design
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(30);
      doc.setTextColor(23, 44, 122); // Navy blue color
  
      // Name
      doc.text(105, 150, `${firstName} ${lastName}`, { align: 'center' });
  
      // Gender Pronouns
      doc.setFont('Helvetica', 'normal');
      doc.setFontSize(25);
      doc.text(105, 160, `${gender}`, { align: 'center' });
  
      // Country
      doc.text(105, 170, `${country}`, { align: 'center' });
  
      // Category (e.g., Delegate)
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(35);
      doc.setTextColor(255, 255, 255); // White color for category
      doc.text(108, 286, `${category.toUpperCase()}`, { align: 'center' });
  
      // Add passport size photo
      //const reader = new FileReader();
      //reader.onload = function(event) {
        // Adjust dimensions and position to fit the designated space
        //doc.addImage(event.target.result, 'JPEG', 80, 180, 50, 50); // Adjust these coordinates and size to fit your design
        const pdfBlob = doc.output('blob');
        const pdfUrl = URL.createObjectURL(pdfBlob);
  
        const pdfOutput = doc.output('blob');
    
  // Create FormData to send to the server
  const formData = new FormData();
  formData.append('file', pdfOutput, `Summit ID -   ${lastName} ${firstName}.pdf`);
  formData.append('apiType', 'uploadSummitID');
  formData.append('email', email);
  formData.append('firstName', firstName);
  formData.append('lastName', lastName);
  formData.append('country', country);
  formData.append('gender', gender);
  formData.append('category', category);
  formData.append('phone', phone);

  // Send the PDF to your server
  axios.post(`${server_app_api_php}/server/index.php`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => {
    //alert(response.data);
    // Setting state after generating the PDF
    //console.log('Application Form Data Received From Server', response.data);
    if(response.data === 'Success'){
    setPdfUrl(pdfUrl);
    setIsSubmitted(true);
    setHandleSubmitBtn(false);
    setFormData({
      firstName: '',
      lastName: '',
      country: '',
      gender: '',
      email: '',
      phone: '',
      category: ''
    });
    alert("Successfully Registered. Click The view ID Button To Download Your ID. Also Check Your Email, We Have Sent The ID");
    }
    else{
      alert('Failed : ', response.data);
        setPdfUrl(null);
        setIsSubmitted(false);
        setHandleSubmitBtn(false);
    }
  })
  .catch(error => {
    alert('Error : Check Your Internet Connection Or Change Your Internet Source');
    setPdfUrl(null);
    setIsSubmitted(false);
    setHandleSubmitBtn(false);
  });
 
        
      //};
  
     /* reader.onerror = function(error) {
        console.error('Error reading photo file: ', error);
      };*/
  
      //reader.readAsDataURL(photo);
    };
  
    img.onerror = function(error) {
      //console.error('Error loading template image: ', error);
    };
  };
  
  
 
  const formStyle = {
    backgroundColor: '#ffffff', // White background
    padding: '30px',
    borderRadius: '8px',
    boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: '0 auto',
  };

  const labelStyle = {
    fontWeight: 'bold',
    color: '#002D62', // Dark blue
  };

  const submitButtonStyle = {
    backgroundColor: '#002D62', // Dark blue
    color: '#ffffff', // White text
    borderRadius: '5px',
    fontWeight: 'bold',
    padding: '10px 20px',
    border: 'none',
  };
  const resetButtonStyle = {
    backgroundColor: '#FF8800', // Dark blue
    color: '#ffffff', // White text
    borderRadius: '5px',
    fontWeight: 'bold',
    padding: '10px 20px',
    border: 'none',
  };

  //style={{ backgroundColor: '#FF5722'}}

  const headerStyle = {
    backgroundColor: '#002D62', // Dark blue
    color: '#ffffff', // White text
    padding: '10px 20px',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '1000',
  };

  const footerStyle = {
    backgroundColor: '#002D62', // Deep blue
    color: '#ffffff',
    padding: '10px 5px',
    position: 'fixed',
    bottom: '0',
    width: '100%',
    zIndex: '1000',
    textAlign: 'center',
  };
  const socialIconStyle = {
    marginLeft: '15px',
    color: '#ffffff',
    fontSize: '1.2em',
  };

  return (
    <>
    <div hidden={!handleSubmitBtn}>
       
        <div class="loader d-flex justify-content-center">
      <div class="spinner-border red-text" style={{width: '10rem', height: '10rem'}} role="status">
          <span class=''>Loading...</span>
        </div>
      </div>
      </div>

    <div>
       <header style={headerStyle} className=''>
        <div className="d-flex justify-content-between align-items-center">
          <div>
         
            <a href="Login" className="text-white">
              <FontAwesomeIcon icon={faSignInAlt} /> Login
            </a>
            <a href="Corrections" className="text-white ml-3">
              <FontAwesomeIcon icon={faEdit} /> Corrections
            </a>
          </div>
          <div>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
            <span className='fa fa-facebook'></span>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
            <span className='fa fa-twitter'></span>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
              
              <span className='fa fa-instagram'></span>
            </a>
          </div>
        </div>
      </header>
    <div className="">
      
      <div className="row justify-content-center mt-5">
      
      <div className="col-md-3">
        <div className='row mt-5'>
      <div className="col-md-12">
   <img src={require('./images/logo.png') } style={{width: '100%'}} />
            </div>
            </div>
     </div>
     
        <div className="col-md-9">
          <div className="card shadow-lg mt-5">
            <div className="card-body p-4"
             
            >
              <h4 className="form-header text-center mb-4 white-text"
              style={{ fontWeight: 'bold', marginBottom: '30px', backgroundColor: '#FF8000' }}
              >Youth SDG Summit - ID Form</h4>
              <form>
                <div className='row'>
                <div className="col-md-4 mb-3 text-left">
                  <label className="form-label" style={labelStyle}>First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                    style={{ borderColor: '#002D62' }}
                  />
                </div>
                <div className="col-md-4 mb-3 text-left">
                  <label className="form-label" style={labelStyle}>Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                    style={{ borderColor: '#002D62' }}
                  />
                </div>
                <div className="col-md-4 mb-3 text-left">
                    <label style={labelStyle}>Country</label>
        <select
          name="country"
          className="form-control"
          value={formData.country}
          onChange={handleInputChange}
          required
          style={{ borderColor: '#002D62' }}
        >
          <option value="">Select Country</option>
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
                </div>
                <div className="col-md-4 mb-3 text-left">
                  <label className="form-label" style={labelStyle}>Pronouns</label>
                  <select
                    className="form-select"
                    name="gender"
                    value={formData.gender}
                    onChange={handleInputChange}
                    required
                    style={{ borderColor: '#002D62' }}
                  >
                    <option value="" disabled>Select Pronouns</option>
                    <option>He/Him/His</option>
                    <option>She/Her/Hers</option>
                  </select>
                </div>
                <div className="col-md-4 mb-3 text-left">
                  <label className="form-label" style={labelStyle}>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    style={{ borderColor: '#002D62' }}
                  />
                </div>
                <div className="col-md-4 mb-3 text-left">
                  <label className="form-label" style={labelStyle}>Phone</label>
                  <input
                    type="tel"
                    className="form-control"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    style={{ borderColor: '#002D62' }}
                  />
                </div>
                <div className="col-md-12 mb-3 text-left">
                  <label className="form-label" style={labelStyle}>Category</label>
                  <select
                    className="form-select"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    required
                    style={{ borderColor: '#002D62' }}
                  >
                    <option value="" disabled>Select Category</option>
                    <option value="Delegate">Delegate</option>
                    <option value="Speaker">Speaker</option>
                    <option value="Performer">Performer</option>
                    <option value="Counsellor">Counsellor</option>
                    <option value="Organiser">Organiser</option>
                    <option value="Delegate Speaker">Delegate Speaker</option>
                    <option value="Volunteer">Volunteer</option>
                  </select>
                </div>

                </div>
                
                <div className='row'>
                    <div className='col-md-6'>
                <button
                  type="button"
                  className="btn w-100 mb-3"
                  disabled={!isFormValid() || handleSubmitBtn}
                  onClick={handleSubmit}
                  style={submitButtonStyle}
                >
                  Submit
                  <FontAwesomeIcon icon={faUpload} className='ml-3' />
                </button>
                </div>
                
                <div className='col-md-6'>
                <button type="button" className="btn w-100 mb-3" onClick={handleReset}
                style={resetButtonStyle}
                disabled={handleSubmitBtn}
                >
          Reset <FontAwesomeIcon icon={faRefresh} className='ml-3'/>
          
        </button>
        </div>

                </div>
              </form>
              {isSubmitted && (
                <button
                  type="button"
                  className="btn btn-success w-100"
                  data-toggle="modal"
                  data-target="#viewIdModal"
                >
                  View ID
                  <span className='fa fa-eye ml-3'></span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="viewIdModal"
        tabIndex="-1"
        aria-labelledby="viewIdModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="viewIdModalLabel">Your Summit ID</h5>
              <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {pdfUrl ? (
                <embed src={pdfUrl} type="application/pdf" width="100%" height="500px" />
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer  class="page-footer mdb-color darken-3" style={{fontFamily: "'Times New Roman', Times, serif"}}>
<div className="container-fluid">
  <div className="row">
    <div className="col-md-4 mt-3">
      <h5 style={{color: '#FF5722', fontFamily: "'Times New Roman', Times, serif"}}>The Youth SDG Summit</h5>
      <p>+260 767 237871 <br />
      youth.sdgsummitzm@gmail.com</p>
    </div>            
   
    <div className="col-md-4 mt-3">
      <h5 style={{color: '#FF5722', fontFamily: "'Times New Roman', Times, serif"}}>Venue Location</h5>
      <p>Ndola Zambia Copperbelt University School of Medicine</p>
    </div>

    
    <div className="col-md-4 mt-3">
      <h5 style={{color: '#FF5722', fontFamily: "'Times New Roman', Times, serif"}}>Venue Map</h5>
      <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.413525596257!2d28.62063141479205!3d-12.986643790852918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x194aa409d03327d3%3A0x34e930d44d15ec8e!2sCopperbelt%20University%20School%20of%20Medicine!5e0!3m2!1sen!2szm!4v1693661711851!5m2!1sen!2szm"
width="100%"
        height="100"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
    
    <div className="col-md-12">      
      <p>developed by <a href="https://www.kanchtech.com" target="_blank" rel="noopener noreferrer" style={{ color: '#ffffff' }}>Kanchtech</a> <br />
      <span style={{ fontSize: '12px', color: '#FF5722' }}>© 2024 The Youth SDG Summit. All rights reserved. </span>        
      </p>
    </div>

  </div>
</div>
</footer>
    </div>

    


</>
  );
};


export default ApplicationForm;
