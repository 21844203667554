import React, { useState, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {
    MDBBtn,
    MDBInput,
    MDBContainer,
    MDBCard,
    MDBCardBody
} from 'mdb-react-ui-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignInAlt, faHomeAlt } from '@fortawesome/free-solid-svg-icons';
import {
    faCheck,
    faTimes,
    faInfoCircle,
    faRefresh,
    faUpload,
    faEdit,
    faUsers
  } from '@fortawesome/free-solid-svg-icons'
  import { useNavigate, redirect } from 'react-router-dom'
const Login = () => {
    const server_app_api_php = window.SERVER_APP_API_PHP;
    let [handleSubmitBtn, setHandleSubmitBtn] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    let navigate = useNavigate()
    const [formData, setFormData] = useState({
        email: '',
        password: ''

    });

    useEffect(() => {
    localStorage.removeItem('userType')
    localStorage.removeItem('userName')
    localStorage.setItem('login', JSON.stringify('No'))
}, [])

    const isFormValid = () => {
        return Object.values(formData).every((value) => value !== '' && value !== null);
      };

 // Handle form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = () => {
        setHandleSubmitBtn(true);

        const updateFormData = new FormData();
        Object.keys(formData).forEach((key) => {
            updateFormData.append(key, formData[key]);
        });
        updateFormData.append('apiType', 'login');
        axios.post(`${server_app_api_php}/server/index.php`, updateFormData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('Data Received From Server', response.data);
          if(response.data.success) {        
            localStorage.setItem('userType', JSON.stringify(response.data.message[0].userType))
            localStorage.setItem('userName', JSON.stringify(response.data.message[0].userName))
            localStorage.setItem('login', JSON.stringify('Yes'))
            navigate('/Dashboard')
            setHandleSubmitBtn(false);
            setIsSubmitted(true);
          }
          else{
            alert('Failed To Login.');
            setHandleSubmitBtn(false);
            setIsSubmitted(false);
          }
        })
        .catch(error => {
            //console.error('Error Login');
            alert('Error : Check Your Internet Connection Or Change Your Internet Source');
            setHandleSubmitBtn(false);
            setIsSubmitted(false);
        });

   };

    const headerStyle = {
        backgroundColor: '#002D62', // Dark blue
        color: '#ffffff', // White text
        padding: '10px 20px',
        position: 'fixed',
        top: '0',
        width: '100%',
        zIndex: '1000',
      };
    const socialIconStyle = {
        marginLeft: '15px',
        color: '#ffffff',
        fontSize: '1.2em',
      };

      const updateButtonStyle = {
        backgroundColor: '#FF8800', // Dark blue
        color: '#ffffff', // White text
        borderRadius: '5px',
        fontWeight: 'bold',
        padding: '10px 20px',
        border: 'none',
      };

    return (
        <>
          <div hidden={!handleSubmitBtn}>
       
       <div class="loader d-flex justify-content-center">
     <div class="spinner-border red-text" style={{width: '10rem', height: '10rem'}} role="status">
         <span class=''>Update...</span>
       </div>
     </div>
     </div>

         <header style={headerStyle} className=''>
        <div className="d-flex justify-content-between align-items-center">
          <div>
         
            <a href="/" className="text-white">
              <FontAwesomeIcon icon={faHomeAlt} /> Home
            </a>
            <a href="Corrections" className="text-white ml-3">
              <FontAwesomeIcon icon={faEdit} /> Corrections
            </a>
          </div>
          <div>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
            <span className='fa fa-facebook'></span>
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
            <span className='fa fa-twitter'></span>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" style={socialIconStyle}>
              
              <span className='fa fa-instagram'></span>
            </a>
          </div>
        </div>
      </header>
        <MDBContainer>
            <MDBCard>
                <MDBCardBody>
                
                <h5 className='form-header mt-5 text-uppercase mdb-color darken-3'>Login
                <FontAwesomeIcon icon={faUsers} className='ml-3' />
                </h5>
                          <div className='text-left'>

                          <label>Email</label>
                              <MDBInput
                                label=""
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className='mb-3'
                            />

<label>password</label>
                            <MDBInput
                            type='password'
                                label=""
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                className='mb-3'
                            />
                            </div>

                   

<div className='row'>
    
     <div className='col-md-12'>
             <button className="btn w-100 mb-3" onClick={handleSubmit} style={updateButtonStyle} 
                            disabled={!isFormValid() || handleSubmitBtn}
                            >Login
                            <FontAwesomeIcon icon={faUpload} className='ml-3' />
                            </button>
                </div>
                
                
                </div>

</MDBCardBody>
            </MDBCard>
        </MDBContainer>


</>
    );
};

export default Login;
